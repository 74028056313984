<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-1">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <!-- <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <v-select
              v-model="perPage"
              :options="pageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>개 보기</label>
          </b-col> -->

          <!-- Search -->
          <b-col
            cols="12"
            md="12"
          >
            <div>
              <b-form-group
                label-for="filter-input"
                class="mb-0"
              >
                <b-input-group class="align-items-center" style="background-color: lightgray; border-radius: 0.5rem;">
                  <!-- <b-input-group-preppend>
                    검색기능
                  </b-input-group-preppend> -->
                  <template #prepend>
                    <feather-icon
                      icon="SearchIcon"
                      size="24"
                      style="margin: 0.5rem;"
                    />
                  </template>
                  
                  <b-form-input
                    v-model="searchTarget"
                    type="search"
                    autofocus
                    placeholder="번호 | 이름 | 주소"
                    >
                  </b-form-input>
                  <!-- v-if="searchTarget !== ''" -->
                  <template #append  >
                    <b style="margin: 0.5rem">검색 결과 : {{ totalUsers }} 개</b>
                    
                  </template>
                  
                  
                </b-input-group>
              </b-form-group>
              <b-button
                v-b-modal.add-user-modal
                variant="outline-primary"
                class="mt-1"
                block
              >
                <span class="align-middle"> 회원 가입 </span>
                <!-- <feather-icon icon="PlusIcon" /> -->
              </b-button>
              
            </div>
            
          </b-col>
        </b-row>
      </div>

      <b-modal
          id="add-user-modal"
          ref="addUserModal"
          ok-title="등록"
          cancel-title="취소"
          cancel-variant="outline-secondary"
          centered
          hide-header
          hide-footer
        >
          <register-modal
            @addCustomer="close()"
          />
        </b-modal>


       <!--  :sort-by="sortBy"
        sort-desc -->

      <b-table
        ref="refUserListTable"
        no-provider-sorting
        class="position-relative"
        :items="userListData"
        :fields="tableColumns"
        :current-page="currentPage"
        :per-page="perPage"
        :filter="filter"
        primary-key="_id"
        hover
        small
        striped 
        responsive
        show-empty
        select-mode="single"
        empty-text="사용자가 없습니다"
        @row-selected="onRowSelected"
      >

      <template #table-busy>
          <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <br>
          <strong>Loading...</strong>
          </div>
      </template>
        <!-- selectable -->

        <!-- Column: User -->
        <template #cell(user)="data">
          <b-media vertical-align="center">
<!--             <template #aside>
              <b-avatar
                size="32"
                :text="avatarText(data.item.userInfo.name)"
                :variant="`light-${resolveUserRoleVariant(data.item.userInfo.role)}`"
                :to="{ name: 'user-view', params: { id: data.item._id } }"
              />
            </template> -->
            <b-link
              :to="{ name: 'user-view', params: { id: data.item._id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              <b style="color:blue">{{ data.item.userInfo.phone }}</b>
              <br> <b>{{ data.item.userInfo.name.substring(0,5) }}{{(data.item.userInfo.name.length > 4)? '…':null}}</b> 
            </b-link>
            <!-- <small class="text-muted">{{ data.item.userInfo.info }}</small> -->
          </b-media>
        </template>

        <!-- Column: Role -->
        <template #cell(role)="data">
          <div class="text-nowrap">
            <!-- <feather-icon
              :icon="resolveUserRoleIcon(data.item.userInfo.role)"
              size="18"
              class="mr-50"
              :class="`text-${resolveUserRoleVariant(data.item.userInfo.role)}`"
            /> -->
           <!--  <span class="align-text-top text-capitalize">{{ data.item.url }}</span> -->
           <!-- <b-badge 
           
            style="float:right"
            variant="secondary" class="mb-sm-0 border float-md-right">
            
          </b-badge> -->
          <!-- <b-button
          variant="outline-info"
          size="sm"
          
          >
            
          </b-button> -->
          <feather-icon 
           size="24"
            style="color: orange"
            @click="showStateModal(data)"
            icon="FileTextIcon" />
          
          
          </div>
          <b-modal 
          :ref="data.item._id" 
          hide-header-close 
          header-bg-variant="info"
          title-tag="h5"
          scrollable
          ok-only
          ok-title="돌아가기"
          ok-variant="secondary"
          button-size='sm'
          :title="'\''+data.item.userInfo.name+'\' 님의 정보'">
            <div :id="data.item._id+'settings'" 
            class="d-block" 
            v-if="data.item.userInfo.role === 'customer'">
            <!-- <div style="text-align:center;border:double">
              <b>로그 데이터</b>
              <br>
              <button style="margin:1rem; width: -webkit-fill-available" @click="developing">정보연계</button>
              <button style="margin:1rem; width: -webkit-fill-available" @click="developing">작동</button>
              <button style="margin:1rem; width: -webkit-fill-available" @click="developing">푸쉬 알림</button>
            </div> -->
              <!-- <span>등록 담당자: {{data.item}}</span><br> -->
              <b>
                <span :style='`color: ${(data.item.userInfo.status === "active")? "green": "red"}`'>{{(data.item.userInfo.status === "active")? "로그인 허용": "로그인 불가"}}</span><hr>
                <a style="color:mediumblue" :href="'tel:010'+(data.item.userInfo.phone).substring(0,4)+(data.item.userInfo.phone).substring(4,8)">
                  <feather-icon icon="PhoneCallIcon" />
                010-{{(data.item.userInfo.phone).substring(0,4)}}-{{(data.item.userInfo.phone).substring(4,8)}} </a>
              </b>
              <hr>
              <span>
                주소: {{data.item.userInfo.address}}<hr>
                작동서버주소: {{data.item.url}}<hr>
                정보: {{data.item.userInfo.info}}
              </span>
              <hr>
              <div >
                <h5 style="font-weight: bold;">푸쉬 알림</h5>
                자동화 알림: <feather-icon :style='`color:${(data.item.pushSetting.automatic)? "blue": "grey"}`' :icon='`${(data.item.pushSetting.automatic)?"CheckCircleIcon": "CircleIcon"}`' /><br>
                네트워크 알림: <feather-icon :style='`color:${(data.item.pushSetting.network)? "blue": "grey"}`' :icon='`${(data.item.pushSetting.automatic)?"CheckCircleIcon": "CircleIcon"}`' /><br>
                <!-- 온도 알림: <feather-icon :style='`color:${(data.item.pushSetting.sensor_temp)? "blue": "grey"}`' :icon='`${(data.item.pushSetting.automatic)?"CheckCircleIcon": "CircleIcon"}`' /><br>
                황화수소 알림: <feather-icon :style='`color:${(data.item.pushSetting.sensor_hs)? "blue": "grey"}`' :icon='`${(data.item.pushSetting.automatic)?"CheckCircleIcon": "CircleIcon"}`' /><br>
                암모니아 알림: <feather-icon :style='`color:${(data.item.pushSetting.sensor_nh)? "blue": "grey"}`' :icon='`${(data.item.pushSetting.automatic)?"CheckCircleIcon": "CircleIcon"}`' /><br>
                우적 알림: <feather-icon :style='`color:${(data.item.pushSetting.rain)? "blue": "grey"}`' :icon='`${(data.item.pushSetting.automatic)?"CheckCircleIcon": "CircleIcon"}`' /><hr> -->
              </div>
              <hr>
              <div v-if="data.item.dataIntegration !== undefined">
                <h5><b>농정원 데이터 연계</b></h5>
                <div v-if="data.item.dataIntegration.accept">
                  <b>데이터 연계 진행중 <feather-icon style='color:green;' icon="UploadCloudIcon" /></b><br>
                  축산업 등록번호: <span style='color:blue;'>{{data.item.dataIntegration.lsind_regist_no}}</span><br>
                  축종: <span style='color:blue;'>{{data.item.dataIntegration.item_code}}</span><br>
                  축사유형: <span style='color:blue;'>{{data.item.dataIntegration.stall_type_code}}</span><br>
                  <div v-if="data.item.dataIntegration.type !== undefined">
                    
                    <span v-for="data in data.item.dataIntegration.type.sensor" :key="data.id">
                      <div v-if="data.active">
                        {{data.name}} 데이터 전송: <feather-icon style='color:blue;' icon="CheckCircleIcon" />
                      </div>
                      <div v-else>
                        {{data.name}} 데이터 전송: <feather-icon style='color:grey;' icon="CircleIcon" />
                      </div>
                    </span>
                    <span v-for="data in data.item.dataIntegration.type.button" :key="data.id">
                      <div v-if="data.active">
                        {{data.name}} 데이터 전송: <feather-icon style='color:blue;' icon="CheckCircleIcon" />
                      </div>
                      <div v-else>
                        {{data.name}} 데이터 전송: <feather-icon style='color:grey;' icon="CircleIcon" />
                      </div>
                    </span>
                    <!-- <span>온도 데이터 연계: {{data.item.dataIntegration.type.sensor}}</span><br>
                    <span>습도 데이터 연계: {{data.item.dataIntegration.item_code}}</span><br>
                    <span>사료급이기 데이터 연계: {{data.item.dataIntegration.item_code}}</span><br> -->
                  </div>
                </div>
                <div v-else>
                    <feather-icon style='color:orange;' icon="AlertTriangleIcon" />
                    <b>데이터 연계 진행중이 아닙니다.</b>
                  </div>
              </div>
              <div
              v-else>
                <feather-icon style='color:orange;' icon="AlertTriangleIcon" />
                <b>데이터 연계 진행중이 아닙니다.</b>
                <br>
              </div>
              
            </div>
            <div v-else>
              <b>관리자 및 담당자는 지원되지 않는 기능입니다.</b>
            </div>
          </b-modal>
        </template>
        

        <!-- Column: Status -->
        <template #cell(status)="data">
            <feather-icon 
            size="24"
            style="color:#B670E6; margin-right: 0.5em"
            @click="showStatusModal(data)"
            :icon="`${data.item.url !== undefined ? 'AirplayIcon': ''}`" />
           <b-modal 
          :ref="`${data.item._id}realtime`" 
          hide-header-close 
          header-bg-variant="info"
          title-tag="h3"
          scrollable
          ok-only
          button-size='sm'
          no-close-on-backdrop
          no-stacking
          ok-title="그만보기"
          @ok="stopInterval()"
          ok-variant="secondary"
          :title="`실시간 보드 정보`">
            <div>
              <b>현재 시간: {{currentTime}}</b><hr>
              <div
              v-for="board of boardList" 
              :key="board._id">
              [ {{board.name}} ]
                <br>
                <a v-if="board.password!==''"
                :href="`http://${board.ddns}:${board.port}`" 
                target='_blank'>URL : {{board.ddns}}:{{board.port}}</a>
                <span v-else>
                    URL : {{board.ddns}}:{{board.port}}
                </span>
                <br><br>
                <b-form-checkbox 
                  :disabled="!board.connect"
                  v-model="board.show" 
                  switch>
                  <span
                    :style="`color:${(board.connect)?'blue':(board.type === 'KC868')? 'orange':'red'}; font-size: larger`">
                    <b> ＞＞＞ {{(board.connect)? '접속 성공, 조작 가능': '접속 실패, 조작 불가'}} ＜＜＜</b>
                  </span>
                </b-form-checkbox>
                
                <tbody
                  v-show="board.show"
                 style="display: table; width: 100%; text-align: center;">
                 <br>
                  <tr>
                    <td 
                    v-show="outArr.pinNo < 9"
                    :style="`background-color: ${outArr.value? '#83FF37': '#DFDFDF'}; border: 2px outset; font-size: larger`"
                    v-for="outArr of board.state" 
                    @click="clickBoardRelay(board, outArr)"
                    :key="outArr.pinNo">
                    {{ (`${outArr.pinNo}`.length > 1)? outArr.pinNo: `0${outArr.pinNo}` }}
                    </td>                    
                  </tr>
                  <br>
                  <tr>
                    <td 
                    v-show="outArr.pinNo >= 9"
                    :style="`background-color: ${outArr.value? '#83FF37': '#DFDFDF'}; border: 2px outset; font-size: larger`"
                    v-for="outArr of board.state" 
                    @click="clickBoardRelay(board, outArr)"
                    :key="outArr.pinNo">
                      {{ (`${outArr.pinNo}`.length > 1)? outArr.pinNo: `0${outArr.pinNo}` }}
                    </td>
                  </tr>
                </tbody>
                <hr>
              </div>
            </div>
           </b-modal>
           <b-modal
            :ref="`${data.item._id}realtime_sensor`"
            title-tag="h3"
            scrollable
            hide-header-close 
            header-bg-variant=""
            ok-title="그만보기"
            @ok="stopInterval()"
            ok-only
            no-close-on-backdrop
            no-stacking
            button-size='md'
            :title="`실시간 센서 정보`">
              <div style="display:contents;text-align:center">
                <div v-if="sensorList.length > 0">
                  <span>
                    <p style="font-weight:bold;margin-bottom:0"> 센서값 수집 시간: {{currentTime}}</p>
                    <p style="margin-bottom:0"> ※ Sensor data is collected every two minutes ※</p>
                  </span>
                    <hr style="margin-top:0">
                    <div
                    v-for="sensor of sensorList" 
                    :key="sensor._id">
                      <div >
                        <table style="margin:0 auto">
                          <tr>
                            <th rowspan='2'>
                              <feather-icon
                                v-if="sensor.type === 'temperature'" 
                                style="color:red;width: fit-content; height: 2rem;"
                                icon="ThermometerIcon" />
                              <feather-icon 
                                v-else-if="sensor.type === 'humidity'" 
                                style="color:skyblue;width: fit-content; height: 2rem;"
                                icon="DropletIcon" />
                              <feather-icon 
                                v-else-if="sensor.type === 'rain'" 
                                style="color:blue;width: fit-content; height: 2rem;"
                                icon="CloudRainIcon" />
                              <feather-icon 
                                v-else
                                style="color:orange;width: fit-content; height: 2rem;"
                                icon="AlertOctagonIcon" />
                            </th>
                            <td>{{sensor.name}}</td>
                          </tr>
                          <tr>
                            <td>
                              <b v-if="sensor.type !== 'rain'" style="font-weight:bold">{{sensor.value || '0'}}{{(sensor.type === 'temperature')? ' ℃': (sensor.type === 'humidity')? ' ％': ' ppm'}} {{sensor.values}}</b>
                              <b v-else>{{(sensor.value == 1)? '비가 오는 중': (sensor.value == 0)? '대기중': '연결 끊김'}}</b> 
                            </td>
                          </tr>
                        </table>
                      </div>
                      <hr>
                    </div>
                </div>
                <div v-else>
                  데이터 수집중...
                  <br><br>
                  잠시만 기다려 주세요.
                </div>
              </div>
            </b-modal>
          <!-- <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.userInfo.status)}`"
            class="text-capitalize"
            @click="toggleStatus(data.item._id, data.item.userInfo.status)"
          >
            {{ data.item.userInfo.status }}
          </b-badge> -->
          <!-- v-if="sensorList.length" -->
        <feather-icon 
          size="24"
          @click="showSensorModal(data)"
          style="color:red"
          icon="ThermometerIcon" />
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item :to="{ name: 'user-view', params: { id: data.item._id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Details</span>
            </b-dropdown-item>

            <b-dropdown-item>
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <!-- <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">총 {{ totalUsers }}명의 사용자가 있습니다.</span>
          </b-col> -->
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="16"
            class="d-flex align-items-center justify-content-center"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              
            >
            </b-pagination>
          </b-col>

        </b-row>
      </div>
    </b-card>

    
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, BMedia, BAvatar, BLink, BButton, BFormCheckbox,
  BBadge, BDropdown, BDropdownItem, BPagination, BFormGroup, BInputGroup, BModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import store from '@/store'
import { getUserData } from '@/auth/utils'
import { getDateString } from '@core/utils/utils'
import RegisterModal from './RegisterModal.vue'
import userListTable from './userListTable'
import axios from 'axios'
import moment from 'moment'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BMedia,
    BButton,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormGroup,
    BInputGroup,
    BFormCheckbox,
    BModal,
    vSelect,
    RegisterModal,
  },
  data() {
    return {
      tableColumns: [
        {
          label: '사용자',
          key: 'user',
          sortable: false,
        },
        /* {
          label: '전화번호',
          key: 'userInfo.phone',
          sortable: true,
        }, */
        {
          label: '주소',
          key: 'userInfo.address',
          sortable: true,
          formatter: value => `${value.substring(0, 2)}···`,
        },
        /* {
          label: '등록정보',
          key: 'role',
          sortable: false,
        }, */
        {
          label: '현재 상태',
          key: 'status',
          sortable: false,
        },
        /* {
          label: '가입일',
          key: 'createdAt',
          sortable: true,
          formatter: value => getDateString(value),
        }, */
        // { key: 'actions' },
      ],

      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15, 20],
      sortBy: '',
      filter: null,
      selectedRow: [],
      targetUser: '',
      boardList:[],
      sensorList: [],
      intervalID: '',
      currentTime: '',
      currentID: '',
      updateTime: '',
      searchTarget: '',
      tempUserList: null
    }
  },
  
  destroyed() {
    this.stopInterval()
  },
  setup() {
    const totalUsers = ref(0)
    let userListData = ref(null)
    store
    .dispatch('users/getCustomers', { id: getUserData().id })
    .then(response => {
        const users = response.data
        let userArray = []
        for(let user of users) if(user.manager === getUserData().id ) userArray.push(user)
        if(getUserData().role === 'admin') userArray = users
        userListData.value = userArray
        for(let [idx, user] of userListData.value.entries()) {
          if(user.userInfo.phone === '12341234') userListData.value.splice(idx, 1)
          if(user.userInfo.phone === '22' || user.userInfo.phone === '33') {
            userListData.value.splice(idx, 1)
            userListData.value.push(user)
          }
        }
        userListData.value.reverse()
        totalUsers.value = userArray.length
        
      })
      .catch(err => {
        console.log(err)
      })

    const roleOptions = [
      { label: '관리자', value: 'admin' },
      { label: '담당자', value: 'manager' },
      { label: '고객', value: 'customer' },
    ]

    const statusOptions = [
      { label: '허용', value: 'active' },
      { label: '불가', value: 'inactive' },
    ]

    const {
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
    } = userListTable()

    return {
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      avatarText,

      roleOptions,
      statusOptions,

      userListData,
      totalUsers,
    }
  },
  watch: {
    async searchTarget (target) {
      let showList = ref(this.userListData).value
      if(this.tempUserList === null) this.tempUserList = showList 
      let result = this.tempUserList.filter(el => el.userInfo.name.indexOf(target) > -1  
      || el.userInfo.phone.indexOf(target) > -1 
      || el.userInfo.address.indexOf(target) > -1)
      this.userListData = []
      this.totalUsers = result.length
      if(result.length >= 1) this.userListData = result
      if(target === '') this.userListData = this.tempUserList
      
    }
  },
  computed: {
    
  },
  methods: {
    stopInterval () {
      clearInterval(this.intervalID)
      clearInterval(this.currentID)
      if(this.intervalID !== '') {
        this.intervalID = ''
        this.currentID = ''
      }
    },
    async getRealTimeData (data, type) {
      return (await store.dispatch('users/getRealTime', { url: data.item.url.split('//')[1] })).data[`${type}`]
    },
    async getBoardList(userId) {
      return (await store.dispatch('device/fetchDevices', { userId })).data
    },
    async getBoardState(id) {
      return (await store.dispatch('device/fetchDevice', { id }))
    },
    async getSensorList(userId) {
      return (await store.dispatch('sensor/fetchSensors', { userId })).data
    },
    async showSensorModal(data) {
      this.currentTime = moment().format('YYYY-MM-DD HH:mm:00')
      this.sensorList = []
      let allowUrl = ['e-farm.iptime.org:10000', 'ewha-api.iptime.org:10000']
      if(data.item.url === undefined || ( allowUrl.findIndex(e=>e === data.item.url.split('//')[1]) === -1)) {
        this.$bvModal.msgBoxOk(`지원되지 않는 컨트롤 서버입니다.`, {
              title: `${data.item.userInfo.name} Monitor Info`,
              size: 'sm',
              buttonSize: 'sm',
              okVariant: 'warning',
              headerClass: 'p-2 border-bottom-0',
              footerClass: 'p-2 border-top-0',
              centered: true
            })
      }
      else {
        try {
          this.$refs[`${data.item._id}realtime_sensor`].show()
          let getUserSensorList = await this.getSensorList(data.item._id)
          console.log(getUserSensorList)
          let realTimeSensorList = await this.getRealTimeData(data, 'sensor')
          for(let data of realTimeSensorList) {
            if(getUserSensorList.findIndex(e => e._id === data.id) > -1) {
              data.value = (data.connect === false)?  0: `${data.value}`
              this.sensorList.push(data)
            }
          }
          if(this.sensorList.length === 0) {
            this.$bvModal.msgBoxOk(`센서가 없습니다.`, {
                title: `${data.item.userInfo.name} Monitor Info`,
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'warning',
                headerClass: 'p-2 border-bottom-0',
                footerClass: 'p-2 border-top-0',
                centered: true
              })
          }
        } catch (error) {
          alert('서버가 응답하지 않습니다')
        }
        
      }
      
      
    },
    async showStatusModal(data) {
      this.currentID = setInterval(() => {
        this.currentTime = moment().format('YYYY-MM-DD HH:mm:ss')
      }, 500)
      this.$refs[`${data.item._id}realtime`].show()


      this.boardList = []
      let getUserBoardList = await this.getBoardList(data.item._id)
      getUserBoardList = getUserBoardList.filter(e => !(e.type === '가상보드'))
      if(getUserBoardList.length > 0) {
        for(let board of getUserBoardList) {
          const status = (await this.getBoardState(board._id)).data
          board.connect = status.connect
          board.state = status.state['out']
          board.show = false
          this.boardList.push(board)
        }
      } 
      else {
        return this.$bvModal.msgBoxOk(`등록된 보드가 존재하지 않습니다`, {
            title: `${data.item.userInfo.name} Monitor Info`,
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            headerClass: ' p-2 border-bottom-0',
            footerClass: 'p-2 border-top-0',
            centered: true
        })
      }

      
      
      /* this.currentID = setInterval(() => {
        this.currentTime = moment().format('YYYY-MM-DD HH:mm:ss')
      }, 1000);
      this.boardList = []
      let getUserBoardList = await this.getBoardList(data.item._id)
      let getRealTimeBoardList = null
      getUserBoardList = getUserBoardList.filter(e => !(e.type === '가상보드'))
      if(data.item.url === undefined) {
        this.$bvModal.msgBoxOk(`컨트롤 서버 미등록`, {
            title: `${data.item.userInfo.name} Monitor Info`,
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'success',
            headerClass: 'p-2 border-bottom-0',
            footerClass: 'p-2 border-top-0',
            centered: true
          })
      }
      else if(getUserBoardList.length === 0) {
        this.$bvModal.msgBoxOk(`상태값을 지원하는 ET0808 보드가 등록되지 않았습니다`, {
            title: `${data.item.userInfo.name}님 장비 상태`,
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'warning',
            headerClass: 'p-2 border-bottom-0',
            footerClass: 'p-2 border-top-0',
            centered: true
          })
      } 
      else {
        store.dispatch('users/getUserConnect', { id: data.item._id})
        .then(async res => {
          if(res.status === 200 && res.data === 'SUCCESS') {
          getRealTimeBoardList = await this.getRealTimeData(data, 'board')
          for(let realTimeBoard of getRealTimeBoardList) {
            for(let userBoard of getUserBoardList) {
              if(userBoard._id === realTimeBoard.id) {
                if(realTimeBoard.error === 1 || realTimeBoard.connect) {
                  userBoard.connect = true
                  userBoard.state = realTimeBoard.state['out']
                  this.updateTime = moment().format('YYYY-MM-DD HH:mm:ss')
                } else userBoard.connect = false
                this.boardList.push(userBoard)
                break
              }
            }
          }
            this.$refs[`${data.item._id}realtime`].show()
        }
        })
        .catch(() => {
          this.$bvModal.msgBoxOk(`※ The controller is not responding. This function is supported only when updated Source Code. ※`, {
            title: `${data.item.userInfo.name} Monitor Info`,
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'success',
            headerClass: 'p-2 border-bottom-0',
            footerClass: 'p-2 border-top-0',
            centered: true
          })
        })
      } */
    },
    showStateModal(data) {
      this.$refs[`${data.item._id}`].show()
    },
    onRowSelected(items) {
      this.selectedRow = items
      this.$router.push({ name: 'user-view', params: { id: this.selectedRow[0]._id } })
    },
    toggleStatus(userId, status) {
      if (status === 'inactive') {
        this.$bvModal
          .msgBoxConfirm('이 사용자를 승인하시겠습니까?', {
            title: '사용자 상태 변경',
            size: 'sm',
            okVariant: 'primary',
            okTitle: '승인',
            cancelTitle: '취소',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: true,
            centered: true,
          })
          .then(value => {
            if (value === true) {
              console.log(userId)
            }
          })
      }
    },
    close() {
      this.$refs.addUserModal.hide()
      this.$router.go()
    },
    developing() {
      alert('개발중인 기능입니다.')
    },
    async clickBoardRelay(board, info) {
      
      const param = {
        command: info.pinNo,
        commandValue: info.value
      }
      try {
        const result = await store.dispatch('device/relayControl', {id: board._id, queryBody: param})
        const idx = this.boardList.findIndex(el => el._id === board._id)
        this.boardList[idx].state[info.pinNo - 1].value = (info.value == 0 ? 1: 0)
        if(result.status) return this.sendToastMsg('info', `Relay ${info.pinNo} ${info.value == 0 ? 'OFF': 'ON'}`)
      } catch (error) {
        console.log(1, error)
        this.sendToastMsg('error', '연결 오류')
      }
    },
    sendToastMsg(msgType, msgContents) {
      this.$toast(msgContents, {
          position: 'top-right',
          timeout: 1000,
          icon: true,
          closeOnClick: true,
          type: msgType,
          rtl: false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
